<template>
  <div>
    <c-card title="동력기계 목록 업로드 (동일한 동력기계번호의 데이터는 자료가 수정됩니다)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'power-machine-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['psiPowerEquipmentNo'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_POWER_MACHINE_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'psiPowerEquipmentNo',
            label: '동력기계번호',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'powerMachineName',
            label: '동력기계명',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'specInfo',
            label: '명세',
            align: 'center',
            style: 'width:240px',
            sortable: false,
          },
          {
            name: 'matQuality',
            label: '주요재질',
            align: 'center',
            style: 'width:240px',
            sortable: false,
          },
          {
            name: 'motorVol',
            label: '전동기용량(kW)',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'protectType',
            label: '방호.보호장치의 종류',
            align: 'center',
            style: 'width:240px',
            sortable: false,
          },
          {
            name: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:120px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다. 데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면,\n\r,동일한 동력기계번호의 데이터는 자료가 수정됩니다.\n\r그대로 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
